<template>
  <div>
    <n-form
      ref="segmentForm"
      :model="form"
      label-placement="top"
      require-mark-placement="left"
      :rules="rules"
    >
      <!-- 1. Nome do Segmento -->
      <n-form-item label="Qual é o nome deste segmento?" path="name">
        <n-input v-model:value="form.name" placeholder="Nome do Segmento" />
      </n-form-item>

      <!-- 2. Escolher entre segmentar todos os contatos ou uma lista existente -->
      <n-form-item
        label="Quais contatos você deseja segmentar?"
        path="segmentType"
      >
        <n-radio-group v-model="form.segmentType">
          <n-button
            @click="segmentType('all')"
            :style="{
              backgroundColor: form.segmentType === 'all' ? 'green' : '',
              color: form.segmentType === 'all' ? 'white' : '',
            }"
            >Segmentar todos os contatos</n-button
          >
          <n-button
            @click="segmentType('list')"
            :style="{
              backgroundColor: form.segmentType === 'list' ? 'green' : '',
              color: form.segmentType === 'list' ? 'white' : '',
            }"
            >Segmentar uma lista existente</n-button
          >
        </n-radio-group>
      </n-form-item>

      <!-- Seleção da lista existente (condicional) -->
      <n-form-item
        v-if="form.segmentType === 'list'"
        label="Lista Existente"
        path="existing_list_id"
      >
        <n-select
          v-model="form.existing_list_id"
          :options="listOptions"
          placeholder="Selecione uma lista"
        />
      </n-form-item>

      <!-- 3. Adicionar Condições -->
      <n-form-item label="Quem você está segmentando?">
        <div style="width: 100%" class="condition-column">
          <div
            v-for="(condition, index) in form.conditions"
            :key="index"
            class="condition-column"
          >
            <div class="condition-group">
              <n-select
                v-model:value="condition.field"
                :options="fieldOptions"
                placeholder="Selecione um campo"
                style="flex: 1"
                @update:value="(value) => onFieldChange(index, value)"
              />
              <n-select
                v-model:value="condition.operator"
                :options="getOperatorsForField(index)"
                placeholder="Selecione um operador"
                style="flex: 1"
              />
              <!-- Renderização dinâmica do input baseado no tipo de campo -->
              <component
                :is="getComponentForField(index)"
                v-model:value="condition.value"
                :placeholder="getPlaceholderForField(index)"
                style="flex: 1"
                :options="getOptionsForField(index)"
              />
              <n-button
                type="error"
                @click="removeCondition(index)"
                size="small"
                round
                >Remover</n-button
              >
            </div>
            <!-- Operadores lógicos "E" e "OU" -->
            <div
              v-if="index < form.conditions.length - 1"
              class="logical-operator"
            >
              <n-radio-group
                v-model="form.conditions[index].logic"
                name="logic"
                size="small"
              >
                <n-button
                  @click="conditionLogic(index, 'AND')"
                  :style="{
                    backgroundColor:
                      form.conditions[index].logic === 'AND' ? 'green' : '',
                    color:
                      form.conditions[index].logic === 'AND' ? 'white' : '',
                  }"
                >
                  E
                </n-button>
                <n-button
                  @click="conditionLogic(index, 'OR')"
                  :style="{
                    backgroundColor:
                      form.conditions[index].logic === 'OR' ? 'green' : '',
                    color: form.conditions[index].logic === 'OR' ? 'white' : '',
                  }"
                >
                  OU
                </n-button>
              </n-radio-group>
            </div>
          </div>
        </div>
      </n-form-item>
      <n-button type="primary" @click="addCondition" size="small" round
        >Adicionar Condição</n-button
      >
      <!-- Botão para salvar o segmento -->
      <div class="form-actions">
        <n-button type="primary" @click="saveSegment">Salvar Segmento</n-button>

        <!-- Botão para buscar leads com base nas condições -->
        <n-button @click="searchLeads">Buscar Leads</n-button>
        <div v-if="estimatedResults > 0">
          <p>Estimativa de resultados: {{ estimatedResults }}</p>
        </div>
      </div>
    </n-form>
  </div>
</template>

<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { MeiliSearch } from "meilisearch";
import { useMessage } from "naive-ui";

const message = useMessage();

const router = useRouter();
const client = new MeiliSearch({
  host: "https://seo-leads.flashgrowth.com.br",
  apiKey: "OQbu_W0WvcQJ1RmukCtnCfVx06czz58NAcg-wVpoPyE",
});

const form = ref({
  name: "",
  segment_type: "all",
  existing_list_id: null,
  conditions: [],
});

const rules = {
  name: {
    required: true,
    message: "Por favor, insira o nome do segmento",
    trigger: "blur",
  },
  existing_list_id: {
    required: true,
    message: "Por favor, selecione uma lista",
    trigger: "change",
    validator: (rule, value) => {
      if (form.value.segmentType === "list" && !value) {
        return new Error("Por favor, selecione uma lista");
      }
      return true;
    },
  },
};

const estimatedResults = ref(0);
const segmentForm = ref(null);

const searchLeads = async () => {
  console.log("Condições de busca:", form.value.conditions);
  try {
    const filters = form.value.conditions
      .map((condition) => {
        const field = condition.field;
        const operator = operatorOptionsMapping[
          fieldOptions.value
            .flatMap((group) => group.children)
            .find((f) => f.value === field).input_type
        ].find((op) => op.value === condition.operator).value;
        const value = condition.value;
        return `${field} ${operator} ${value}`;
      })
      .join(" AND ");

    const searchResults = await client.index("leads").search("", {
      filter: filters,
    });

    estimatedResults.value = searchResults.nbHits;

    console.log("Número de leads encontrados:", searchResults.nbHits);
  } catch (error) {
    console.error("Erro ao buscar leads no Meilisearch:", error);
  }
};

const listOptions = ref([
  { label: "Testers Flashvolve", value: "Testers Flashvolve" },
  { label: "Profissas", value: "Profissas" },
]);

const fieldOptions = ref([
  {
    type: "group",
    label: "Lead",
    key: "Lead",
    children: [
      /*{ label: "Nome Completo", value: "full_name", input_type: "string" },
      { label: "Primeiro Nome", value: "firstName", input_type: "string" },
      { label: "Sobrenome", value: "lastName", input_type: "string" },*/
      { label: "Cargo", value: "position", input_type: "string" },
      { label: "Email", value: "email", input_type: "string" },
      /*{ label: "Localização", value: "location", input_type: "string" },
      {
        label: "Último Email",
        value: "last_email",
        input_type: "string",
        search: true,
      },

      { label: "Telefone", value: "phone", input_type: "string" },
      { label: "LinkedIn", value: "linkedin", input_type: "string" },
      { label: "Imagem", value: "image", input_type: "string" },
      {
        label: "Data de Criação",
        value: "created_at",
        input_type: "date-picker",
      },
      {
        label: "Data de Atualização",
        value: "updated_at",
        input_type: "date-picker",
      },*/
    ],
  },
  /*{
    type: "group",
    label: "Empresa",
    key: "Company",
    children: [
      {
        label: "Empresa",
        value: "company",
        input_type: "select",
        relational: true,
        relation_to: "Company",
      },
      {
        label: "País",
        value: "company_country",
        input_type: "select",
        relational: true,
        relation_to: "CompanyCountry",
      },
      {
        label: "Indústria",
        value: "company_industry",
        input_type: "select",
        relational: true,
        relation_to: "CompanyIndustry",
      },
      {
        label: "Número de Colaboradores",
        value: "company_employee_count",
        input_type: "string",
        relational: true,
        relation_to: "CompanyEmployeeCount",
      },
      {
        label: "Faturamento",
        value: "company_revenue",
        input_type: "string",
        relational: true,
        relation_to: "CompanyRevenue",
      },
    ],
  },
  {
    type: "group",
    label: "Relacional",
    key: "Relational",
    children: [
      {
        label: "Cadência",
        value: "lead_cadence_id",
        input_type: "select",
        relational: true,
        relation_to: "LeadCadence",
      },
      {
        label: "Oportunidade",
        value: "oportunity_id",
        input_type: "select",
        relational: true,
        relation_to: "Opportunity",
      },
      {
        label: "Tags",
        value: "tags",
        input_type: "select",
        relational: true,
        relation_to: "Tag",
      },
      {
        label: "Campos Personalizados",
        value: "custom_fields",
        input_type: "select",
        relational: true,
        relation_to: "CustomField",
      },
    ],
  },*/
]);

const operatorOptionsMapping = {
  string: [
    { label: "é", value: "=" },
    { label: "não é", value: "!=" },
    { label: "contém", value: "LIKE" },
    { label: "não contém", value: "NOT LIKE" },
    { label: "está em branco", value: "IS NULL" },
    { label: "não está em branco", value: "IS NOT NULL" },
  ],
  "date-picker": [
    { label: "é", value: "=" },
    { label: "não é", value: "!=" },
    { label: "antes de", value: "<" },
    { label: "depois de", value: ">" },
    { label: "está em branco", value: "IS NULL" },
    { label: "não está em branco", value: "IS NOT NULL" },
  ],
  select: [
    { label: "é", value: "=" },
    { label: "não é", value: "!=" },
    { label: "está em branco", value: "IS NULL" },
    { label: "não está em branco", value: "IS NOT NULL" },
  ],
};

const getOperatorsForField = (index) => {
  const field = form.value.conditions[index].field;
  const fieldOption = fieldOptions.value
    .flatMap((group) => group.children)
    .find((f) => f.value === field);
  return fieldOption ? operatorOptionsMapping[fieldOption.input_type] : [];
};

const getComponentForField = (index) => {
  const field = form.value.conditions[index].field;
  const fieldOption = fieldOptions.value
    .flatMap((group) => group.children)
    .find((f) => f.value === field);

  if (!fieldOption) return "n-input";

  switch (fieldOption.input_type) {
    case "date-picker":
      return "n-date-picker";
    case "select":
      return "n-select";
    default:
      return "n-input";
  }
};

const getPlaceholderForField = (index) => {
  const field = form.value.conditions[index].field;
  const fieldOption = fieldOptions.value
    .flatMap((group) => group.children)
    .find((f) => f.value === field);

  return fieldOption
    ? `Insira ${fieldOption.label.toLowerCase()}`
    : "Insira o valor";
};

const getInputTypeForField = (index) => {
  const field = form.value.conditions[index].field;
  const fieldOption = fieldOptions.value
    .flatMap((group) => group.children)
    .find((f) => f.value === field);

  return fieldOption && fieldOption.input_type === "date-picker"
    ? "date"
    : "text";
};

const getOptionsForField = (index) => {
  const field = form.value.conditions[index].field;
  const fieldOption = fieldOptions.value
    .flatMap((group) => group.children)
    .find((f) => f.value === field);

  if (fieldOption && fieldOption.input_type === "select") {
    // Aqui você deve retornar as opções específicas para este campo select
    // Por exemplo:
    return [
      { label: "Opção 1", value: "option1" },
      { label: "Opção 2", value: "option2" },
      // ... mais opções conforme necessário
    ];
  }

  return [];
};

const addCondition = () => {
  form.value.conditions.push({
    field: "",
    operator: "",
    value: "",
    logic: "",
  });
};

const removeCondition = (index) => {
  form.value.conditions.splice(index, 1);
};

const conditionLogic = (index, logic) => {
  form.value.conditions[index].logic = logic;
};
const onFieldChange = (index, value) => {
  form.value.conditions[index].field = value;
  form.value.conditions[index].operator = "";

  const fieldOption = fieldOptions.value
    .flatMap((group) => group.children)
    .find((f) => f.value === value);

  if (fieldOption.input_type === "date-picker") {
    form.value.conditions[index].value = null;
  } else if (fieldOption.input_type === "select") {
    form.value.conditions[index].value = [];
  } else {
    form.value.conditions[index].value = "";
  }

  console.log(
    `Field changed: ${value}, Condition: `,
    form.value.conditions[index]
  );
};

const segmentType = (type) => {
  form.value.segmentType = type;
};

const saveSegment = () => {
  console.log("Nome do Segmento:", form.value.name);
  fetch("https://api-db.flashgrowth.com.br/segments", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "VjNMgnNt9JaRtDc2tcPDWdGzni13",
    },
    body: JSON.stringify(form.value),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error("Erro ao salvar segmento");
      }
    })
    .then((data) => {
      console.log("Segmento salvo:", data);
      form.value = {
        name: "",
        segment_type: "all",
        existing_list_id: null,
        conditions: [],
      };
      message.success("'Segmento salvo com sucesso!'");
      router.push("/automation/contacts");
    })
    .catch((error) => {
      console.log("Erro ao salvar segmento:", error);
    });
};
</script>

<style scoped>
.form-actions {
  margin-top: 20px;
  text-align: right;
}

.condition-column {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.condition-group {
  display: flex;
  gap: 10px;
  align-items: center;
}

.logical-operator {
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-start;
}
</style>
